import './index.css';
import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import productsData from "../../Data/productsData";
import {Helmet} from "react-helmet-async";

const SearchPage = () => {
    const {searchQuery} = useParams()
    const thisProduct = productsData.filter((productSearch) => {
        if(searchQuery === "") {
            return productsData
        } else if(productSearch.name.toLocaleLowerCase().includes(searchQuery.toLowerCase()) || productSearch.description.toLocaleLowerCase().includes(searchQuery.toLowerCase())) {
            return productSearch
        }
    })
    const products = thisProduct.map (productResult => {
        return (
            <div key={productResult.name}>
                <a href={`/${productResult.category}/${(productResult.name).replace(/\s+/g, '-').toLowerCase()}`} className="product-card-link">
                    <div className="card p-3 shadow-lg mb-4">
                        <div className="row">
                            <div className="col-md-3 p-2">
                                <div className="title-bold desktop-hidden tablet-hide">
                                    {productResult.name}
                                </div>
                                <img draggable="false" className="product-image" src={productResult.image} alt="product-image" />
                            </div>
                            <div className="col-md-8 p-2">
                                <div className="row px-1">
                                    <div className="title-bold">
                                        <h5 className="title-bold mobile-hidden tablet-show">{productResult.name}</h5>
                                    </div>
                                </div>
                                <div className="row px-1 mt-3">
                                    <div className="col-6 text-uppercase">
                                        BRAND
                                    </div>
                                    <div className="col-6">
                                        {productResult.make}
                                    </div>
                                </div>
                                <div className="separator my-2"></div>

                                <div className="row px-1">
                                    <div className="col-6 text-uppercase">
                                        MODEL
                                    </div>
                                    <div className="col-6">
                                        {productResult.model}
                                    </div>
                                </div>

                                <div className="separator my-2"></div>

                                <div className="row px-1">
                                    <div className="col-6 text-uppercase">
                                        PRETURI DE LA
                                    </div>
                                    <div className="col-6">
                                        {productResult.dayPrice}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        );
    });

    return (
        <div>
            {products}
        </div>
    );
};

export default SearchPage;
