import './index.css';
import React from 'react';
import productsData from "../../Data/productsData"

const RecommendedComponent = () => {

    const products = productsData;
    function getMultipleRandom(products, num) {
        const shuffled = [...products].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, num);
    }
    const product = getMultipleRandom(products, 3);

    return (

        <div className="card shadow-lg my-5 p-3">
            <div className="text-bold mt-2">
                ALTE PRODUSE RECOMANDATE
            </div>

            <div className="mt-2">
                <div className="row gx-4 p-2 d-flex flex-wrap justify-content-around">
                    <div className="col-md-4">
                        <a href={`/${product[0].category}/${(product[0].name).replace(/\s+/g, '-').toLowerCase()}`} className="recommended-link">
                            <div className="shadow my-4 pb-1 recommended-column">
                                <div className="text-center justify-content-center">
                                    <img draggable="false" className="recommended-product-image"
                                         src={product[0].image}
                                         alt="product-image"/>
                                </div>
                                <div className="my-4 mx-3 title-bold">
                                    {product[0].name}
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-4">
                        <a href={`/${product[1].category}/${(product[1].name).replace(/\s+/g, '-').toLowerCase()}`} className="recommended-link">
                            <div className="shadow my-4 pb-1 recommended-column">
                                <img draggable="false" className="recommended-product-image"
                                     src={product[1].image}
                                     alt="product-image"/>
                                <div className="my-4 mx-3 title-bold">
                                    {product[1].name}
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-4">
                        <a href={`/${product[2].category}/${(product[2].name).replace(/\s+/g, '-').toLowerCase()}`} className="recommended-link">
                            <div className="shadow my-4 pb-1 recommended-column">
                                <img draggable="false" className="recommended-product-image"
                                     src={product[2].image}
                                     alt="product-image"/>
                                <div className="my-4 mx-3 title-bold">
                                    {product[2].name}
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecommendedComponent;
