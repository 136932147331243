import './index.css';
import React, { useState } from 'react';
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import $ from 'jquery';

const SidebarComponent = () => {

    function SearchFunction() {
        const getValue = $("#search_query")
        $("#search_btn").attr("href", '/results/' + getValue.val());
    }

    const [btnState, setBtnState] = useState(false);

    function handleClick() {
        setBtnState(btnState => !btnState)
    }

    let toggleClassCheck = btnState ? 'active': '';

    return (
        <div className="card p-3 shadow-lg sidebar sticky-top">
            <div className="form-group my-2">
                <label htmlFor="search">Cauta</label>
                <div className="d-flex">
                    <input type="text" autoComplete="off" onChange={(event) => SearchFunction()} className="form-control rounded-0" name="search_query" id="search_query" aria-describedby="search" placeholder="Echipament" />
                    <a href="" id="search_btn" className="btn btn-primary ms-2"><FiSearch/></a>
                </div>
            </div>

            <button onClick={handleClick} className="show-category-button desktop-hidden btn mt-3 btn-primary">Filtreaza Categorii</button>

            <div className="my-3 mobile-hidden">
                <Link to="/" className="btn btn-primary">Afiseaza toate</Link>
            </div>

            <div className="my-4 remove-bottom-margin-mobile">
                <h5 className="category mb-4 mobile-hidden">Categorii</h5>
                <ul id="category-list" className={`category-list-off-mobile${toggleClassCheck} mobile-padding-bottom`}>
                    <li className="category-li"><a href="/categorie/flex-cu-acumulator" className="category-link">Flex cu acumulator</a></li>
                    <li className="category-li"><a href="/categorie/flex-cu-fir" className="category-link">Flex cu fir</a></li>
                    <li className="category-li"><a href="/categorie/malaxor-de-mana" className="category-link">Malaxor de mana</a></li>
                    <li className="category-li"><a href="/categorie/malaxor-betoniera" className="category-link">Malaxor / betoniera</a></li>
                    <li className="category-li"><a href="/categorie/compactoare" className="category-link">Compactoare</a></li>
                    <li className="category-li"><a href="/categorie/generatoare" className="category-link">Generatoare</a></li>
                    <li className="category-li"><a href="/categorie/aspiratoare-industriale" className="category-link">Aspiratoare industriale</a></li>
                    <li className="category-li"><a href="/categorie/masini-de-slefuit-beton" className="category-link">Masini de slefuit beton</a></li>
                    <li className="category-li"><a href="/categorie/masini-de-slefuit-glet" className="category-link">Masini de slefuit glet</a></li>
                    <li className="category-li"><a href="/categorie/echipamente-spalare-presiune" className="category-link">Echipamente spalare presiune</a></li>
                    <li className="category-li"><a href="/categorie/pompe-var" className="category-link">Pompe var</a></li>
                    <li className="category-li"><a href="/categorie/tunuri-caldura-motorina" className="category-link">Tunuri de caldura motorina</a></li>
                    <li className="category-li"><a href="/categorie/dezumidificatoare" className="category-link">Dezumidificatoare</a></li>
                    <li className="category-li"><a href="/categorie/drujbe" className="category-link">Drujbe</a></li>
                    <li className="category-li"><a href="/categorie/nivele-optice" className="category-link">Nivele optice</a></li>
                    <li className="category-li"><a href="/categorie/nivele-laser" className="category-link">Nivele laser</a></li>
                    <li className="category-li"><a href="/categorie/pistoale-gaz-cuie" className="category-link">Pistoale gaz impuscat cuie</a></li>
                    <li className="category-li"><a href="/categorie/demolatoare-rotopercutoare-percutie" className="category-link">Demolatoare si rotopercutoare cu percutie</a></li>
                    <li className="category-li"><a href="/categorie/masini-taiat-placi-ceramice" className="category-link">Masini de taiat placi ceramice</a></li>
                    <li className="category-li"><a href="/categorie/schele-metalice" className="category-link">Schele metalice</a></li>
                    <li className="category-li"><a href="/categorie/cofraje" className="category-link">Cofraje</a></li>
                    <li className="category-li"><a href="/categorie/popi-metalici" className="category-link">Popi metalici</a></li>
                </ul>
            </div>
        </div>
    );
};

export default SidebarComponent;
