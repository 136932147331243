import './index.css';
import React from 'react';
import {useLocation} from "react-router-dom";
import { useState } from "react";

const HeaderComponent = () => {

    const location = useLocation();
    const path = location.pathname;
    const [display, setDisplay] = useState(
        path !== "/" ? false : true
    );

    return (
        <>
            {display && (
                <div className="header-wrapper mb-5 margin-bottom-0-mobile">
                    <div className="container">
                        <div className="row header-content">
                            <div className="col-md-6 d-flex flex-wrap align-items-center px-5">
                                <div>
                                    <h1>INCHIRIERI <span className="text-green text-bold">SCULE</span> SI <span className="text-green text-bold">ECHIPAMENTE</span> PENTRU URMATORUL TAU PROIECT</h1>
                                    <div className="my-4">
                                        Peste <span className="text-green text-bold">100</span> de echipamente de constructie si amenajari la preturi incepand de la <span className="text-green text-bold">120 lei</span> pe zi
                                    </div>
                                    <div className="mt-4 d-inline-block">
                                        <a href="/contact" className="btn btn-primary text-uppercase px-5">Contacteaza-ne</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex flex-wrap align-items-center">
                                <img draggable="false" className="header-image hide-header" src={require('./../../Assets/images/header_1.png')} alt="header_image" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default HeaderComponent;
