import './index.css';
import React from 'react';
import {Helmet} from "react-helmet-async";

const PolicyPage = () => {
    return (
        <div>
            <Helmet>
                <title>Politica Cookies</title>
                <meta name="description" content="Politica Cookies"/>
                <link rel="canonical" href="/politica-cookie" />
                <meta name="robots" content="noindex"/>
            </Helmet>
            <div className="card shadow-lg p-5">
                <h4 className="text-green">Conditii generale de inchiriere</h4>
                <div className="my-3">
                    In constructie
                </div>
            </div>
        </div>
    );
};

export default PolicyPage;
