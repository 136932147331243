import './index.css';
import React from 'react';

const NavbarComponent = () => {
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <div className="container">
                    <a className="navbar-brand text-green" href="/">SynTech Equipments</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav container">
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/">Acasa</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/conditii-de-inchiriere">Conditii inchiriere</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://epoxfloor.ro/" target="_blank">Expert in pardoseli</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default NavbarComponent;
