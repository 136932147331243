import './index.css';
import React from 'react';
import productsData from "../../Data/productsData";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";

const CategoryPage = () => {

    const {categoryName} = useParams()
    const thisProduct = productsData.filter(prod => prod.category === categoryName)
    const products = thisProduct.map (product => {

    return (
        <div key={product.name}>

            <Helmet>
                <title>{(categoryName).replace('-', ' ')}</title>
                <meta name="description" content={product.name}/>
                <link rel="canonical" href={'/categorie/' + categoryName} />
            </Helmet>

            <a href={`/${product.category}/${(product.name).replace(/\s+/g, '-').toLowerCase()}`} className="product-card-link">
                <div className="card p-3 shadow-lg mb-4">
                    <div className="row">
                        <div className="col-md-3 p-2">
                            <div className="title-bold desktop-hidden tablet-hide">
                                {product.name}
                            </div>
                            <img draggable="false" className="product-image" src={product.image} alt="product-image" />
                        </div>
                        <div className="col-md-8 p-2">
                            <div className="row px-1">
                                <div className="title-bold">
                                    <h5 className="title-bold mobile-hidden tablet-show">{product.name}</h5>
                                </div>
                            </div>
                            <div className="row px-1 mt-3">
                                <div className="col-6 text-uppercase">
                                    BRAND
                                </div>
                                <div className="col-6">
                                    {product.make}
                                </div>
                            </div>
                            <div className="separator my-2"></div>

                            <div className="row px-1">
                                <div className="col-6 text-uppercase">
                                    MODEL
                                </div>
                                <div className="col-6">
                                    {product.model}
                                </div>
                            </div>

                            <div className="separator my-2"></div>

                            <div className="row px-1">
                                <div className="col-6 text-uppercase">
                                    PRETURI DE LA
                                </div>
                                <div className="col-6">
                                    {product.dayPrice}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
});

    return (
        <div>
            {products}
        </div>
    );
};

export default CategoryPage;
