import './index.css';
import React, { useEffect, useState } from "react";

const CallButton = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);

    return (
        <div className="bottom-0 fixed-bottom">
            {showButton && (
                <a href="tel:+40747479634" className="text-white text-decoration-none">
                    <div className="call-button">
                        SUNA ACUM
                    </div>
                </a>
            )}
        </div>
    );
};

export default CallButton;
