import './index.css';
import React from 'react';
import DetailsComponent from "../../Components/ProductDetails";
import RecommendedComponent from "../../Components/Recommended";

const ProductPage = () => {
    return (
        <div>
            <DetailsComponent/>
            <RecommendedComponent/>
        </div>
    );
};

export default ProductPage;
