export default [
    {
        id: "1",
        category: "flex-cu-acumulator",
        name: "Polizor DeWalt DCG414T2",
        image: "/product_images/flex-cu-acumulator/DCG414T2.webp",
        availability: 1,
        make: "DeWalt",
        model: "DCG414t2",
        location: "Galati, Romania",
        description: "Polizor unghiular cu acumulatori si statie de incarcare 54V XR Flexvolt Li-ion 6.0Ah DeWalt - DCG414T2",
        quantity: "2 BUC",
        dayPrice: "120 ron",
        weekPrice: "756 ron",
        monthPrice: "2.880 ron"
    },
    {
        id: "2",
        category: "flex-cu-acumulator",
        name: "Polizor DeWalt DCG405P2",
        image: "/product_images/flex-cu-acumulator/DCG405P2.webp",
        availability: 1,
        make: "DeWalt",
        model: "DCG405P2",
        location: "Galati, Romania",
        description: "Polizor unghiular pe acumulator Dewalt DCG405P2-QW, 1000 W putere nominala, 18 V, 5 Ah acumulator Li-Ion, 9000 RPM, 125 mm diametru disc, M14 sistem prindere",
        quantity: "1 BUC",
        dayPrice: "120 ron",
        weekPrice: "756 ron",
        monthPrice: "2.880 ron"
    },
    {
        id: "3",
        category: "flex-cu-acumulator",
        name: "Polizor Bosch GWS 18V-10 SC",
        image: "/product_images/flex-cu-acumulator/18V-10.jpeg",
        availability: 1,
        make: "Bosch",
        model: "GWS 18V-10 SC",
        location: "Galati, Romania",
        description: "Polizor unghiular, Bosch, 2 acumulatori, 18V, 5.0 Ah, GWS 18V-10 SC 125",
        quantity: "1 BUC",
        dayPrice: "120 ron",
        weekPrice: "756 ron",
        monthPrice: "2.880 ron"
    },
    {
        id: "4",
        category: "flex-cu-fir",
        name: "Polizor DeWalt DWE 4257",
        image: "/product_images/flex-cu-fir/4257.webp",
        availability: 1,
        make: "DeWalt",
        model: "DWE 4257",
        location: "Galati, Romania",
        description: "putere 1500W, turatie reglabila 2800 - 10000 rpm.",
        quantity: "3 BUC",
        dayPrice: "120 ron",
        weekPrice: "756 ron",
        monthPrice: "2.880 ron"
    },
    {
        id: "5",
        category: "malaxor-de-mana",
        name: "Hikoki UN16 VST2",
        image: "/product_images/malaxor-de-mana/VST2.webp",
        availability: 1,
        make: "Hikoki",
        model: "UN16 VST2",
        location: "Galati, Romania",
        description: "Mixerul Hikoki UM16VST2 este un amestecator profesional cu o putere de 1600 W si o constructie robusta cu manere tip volan pentru a asigura o priza excelenta si pentru a oferi posibilitatea de a mixa materiale dense. In plus acest mixer dispune si de o bara de protectie din metal ce asigura manipularea in conditii diverse fara pericol.",
        quantity: "2 BUC",
        dayPrice: "150 ron",
        weekPrice: "945 ron",
        monthPrice: "3.600 ron"
    },
    {
        id: "6",
        category: "malaxor-de-mana",
        name: "Collomix Xo4",
        image: "/product_images/malaxor-de-mana/Xo4.jpeg",
        availability: 1,
        make: "Collomix",
        model: "Xo4",
        location: "Galati, Romania",
        description: "Amestecator manual compact cu doua trepte de viteza, pentru o gama larga de utilizari si amestecuri cu o capacitate de pana la 65 litri. Conceput pentru a fi extrem de usor de folosit, are un design ergonomic si un raport excelent intre angrenaj si controlul electronic al vitezei. Utilizand palete cu prindere HEXAFIX® este ideal pentru adezivi, mortare, vopsele, tencuieli, etansari, etc. Fabricat in Germania.",
        quantity: "2 BUC",
        dayPrice: "150 ron",
        weekPrice: "945 ron",
        monthPrice: "3.600 ron"
    },
    {
        id: "7",
        category: "malaxor-betoniera",
        name: "Betoniera verticala stationar Collomix TMX 1500",
        image: "/product_images/malaxor-betoniera/TMX1500.jpeg",
        availability: 1,
        make: "Collomix",
        model: "TMX 1500",
        location: "Galati, Romania",
        description: "TMX1500 este un amestecator automat stationar, destinat amestecarii materialelor de tip pulberi (in saci), sape, mortare, cu capacitate maxima utila de amestecare de 120 l",
        quantity: "1 BUC",
        dayPrice: "500 ron",
        weekPrice: "3.150 ron",
        monthPrice: "12.000 ron"
    },
    {
        id: "8",
        category: "malaxor-betoniera",
        name: "Betoniera Liderman 200L LM200",
        image: "/product_images/malaxor-betoniera/LM200.webp",
        availability: 1,
        make: "Liderman",
        model: "LM200",
        location: "Galati, Romania",
        description: "Betoniera Liderman LM200,1300W, 200L, coroana din fonta, profesionala",
        quantity: "1 BUC",
        dayPrice: "200 ron",
        weekPrice: "1260 ron",
        monthPrice: "4.800 ron"
    },
    {
        id: "9",
        category: "compactoare",
        name: "Placa compactoare Bomag 18-45",
        image: "/product_images/compactoare/BOMAG18.jpeg",
        availability: 1,
        make: "Bomag",
        model: "18/45",
        location: "Galati, Romania",
        description: "Placa compactoare Bomag 18/45 este recomandata in lucrari de terasament si constructii de asfalt. Trasee de conducte, santuri si conducte de canalizare, depozite, fundatii si lucrari de reparatii pe asfalt.",
        quantity: "1 BUC",
        dayPrice: "250 ron",
        weekPrice: "1.575 ron",
        monthPrice: "6.000 ron"
    },
    {
        id: "10",
        category: "compactoare",
        name: "Mai compactor Bomag BT65",
        image: "/product_images/compactoare/BOMAGBT65.jpeg",
        availability: 1,
        make: "Bomag",
        model: "BT65",
        location: "Galati, Romania",
        description: "Mai compactor BT65 BOMAG, motor Honda GXR120, putere motor 4CP, greutate 68kg",
        quantity: "1 BUC",
        dayPrice: "250 ron",
        weekPrice: "1.575 ron",
        monthPrice: "6.000 ron"
    },
    {
        id: "11",
        category: "generatoare",
        name: "Generator 220V 7kW",
        image: "/product_images/generatoare/7kW.webp",
        availability: 1,
        make: "-",
        model: "-",
        location: "Galati, Romania",
        description: "7000 W, 230V, stabilizator de tensiune (AVR), motor benzina cu demaraj electric",
        quantity: "1 BUC",
        dayPrice: "250 ron",
        weekPrice: "1.575 ron",
        monthPrice: "6.000 ron"
    },
    {
        id: "12",
        category: "generatoare",
        name: "Generator 380V 20kW",
        image: "/product_images/generatoare/20kW.png",
        availability: 1,
        make: "-",
        model: "-",
        location: "Galati, Romania",
        description: "20000 W, 380V, stabilizator de tensiune (AVR)",
        quantity: "1 BUC",
        dayPrice: "400 ron",
        weekPrice: "2.520 ron",
        monthPrice: "9.600 ron"
    },
    {
        id: "13",
        category: "aspiratoare-industriale",
        name: "Master Vac M450S-003",
        image: "/product_images/aspiratoare-industriale/M450S.png",
        availability: 1,
        make: "Master Vac",
        model: "M450S-003",
        location: "Galati, Romania",
        description: "Aspirator industrial Mastervac M450S-003. Aspirator industrial monofazat. Aspira materiale solide, lichide si praf",
        quantity: "3 BUC",
        dayPrice: "300 ron",
        weekPrice: "1.890 ron",
        monthPrice: "7.200 ron"
    },
    {
        id: "14",
        category: "aspiratoare-industriale",
        name: "Bosch Gas 35L",
        image: "/product_images/aspiratoare-industriale/GAS35L.webp",
        availability: 1,
        make: "Bosch",
        model: "Gas 35L",
        location: "Galati, Romania",
        description: "Aspirator universal Bosch - GAS 35 L SFC - 1380W - 35L asigura o curatare foarte confortabila si semiautomata a filtrelor (SFC+)",
        quantity: "2 BUC",
        dayPrice: "150 ron",
        weekPrice: "945 ron",
        monthPrice: "3.600 ron"
    },
    {
        id: "15",
        category: "aspiratoare-industriale",
        name: "Aspirator cu insertie apa Elsea EWVP330",
        image: "/product_images/aspiratoare-industriale/EWVP330.webp",
        availability: 1,
        make: "Elsea",
        model: "EWVP330",
        location: "Galati, Romania",
        description: "Extractor indicat pentru curatarea covoarelor si mocheta , tapiterie auto. Aspirator destinat pentru curatarea spatiilor medii cu rezervor de plastic ABS.",
        quantity: "1 BUC",
        dayPrice: "200 ron",
        weekPrice: "1.260 ron",
        monthPrice: "4.800 ron"
    },
    {
        id: "16",
        category: "masini-de-slefuit-beton",
        name: "Freza beton Bimack 650",
        image: "/product_images/masini-de-slefuit-beton/BIMACK650.jpeg",
        availability: 1,
        make: "Bimack",
        model: "650",
        location: "Galati, Romania",
        description: "10HP & 15HP trifazic masina profesionala de slefuit beton, cap planetar",
        quantity: "1 BUC",
        dayPrice: "3000 ron (cu personal + consumabile)",
        weekPrice: "-",
        monthPrice: "-"
    },
    {
        id: "17",
        category: "masini-de-slefuit-beton",
        name: "Freza beton Bimack 551",
        image: "/product_images/masini-de-slefuit-beton/BIMACK551.jpeg",
        availability: 1,
        make: "Bimack",
        model: "551",
        location: "Galati, Romania",
        description: "10HP & 15HP trifazic masina profesionala de slefuit beton, cap planetar",
        quantity: "1 BUC",
        dayPrice: "2000 ron (cu personal + consumabile)",
        weekPrice: "-",
        monthPrice: "-"
    },
    {
        id: "18",
        category: "masini-de-slefuit-beton",
        name: "Monodisc Bimack Challenger 451",
        image: "/product_images/masini-de-slefuit-beton/CHALLANGER451.jpeg",
        availability: 1,
        make: "Bimack",
        model: "Challenger 451",
        location: "Galati, Romania",
        description: "Mașina Challenger 451 are capacitatea de a regla rotația sculelor planetare de la 450 la 1000 rpm.",
        quantity: "1 BUC",
        dayPrice: "400 ron",
        weekPrice: "2.520 ron",
        monthPrice: "9.600 ron"
    },
    {
        id: "19",
        category: "masini-de-slefuit-beton",
        name: "Monodisc Taski Ergodisc 165",
        image: "/product_images/masini-de-slefuit-beton/ERGODISC165.jpeg",
        availability: 1,
        make: "Taski",
        model: "Ergodisc 165",
        location: "Galati, Romania",
        description: "Masina monodisc Taski Ergodisc 165 de la Diversey, de viteza redusa, pentru sarcini multiple de curatenie, va asigura: ergonomie, siguranta, simplitate si versatilitate",
        quantity: "3 BUC",
        dayPrice: "400 ron",
        weekPrice: "2.520 ron",
        monthPrice: "9.600 ron"
    },
    {
        id: "20",
        category: "masini-de-slefuit-beton",
        name: "Freza Bosch 15 GBR CA",
        image: "/product_images/masini-de-slefuit-beton/GBR.webp",
        availability: 1,
        make: "Bosch",
        model: "15 GBR CA",
        location: "Galati, Romania",
        description: "Masina monodisc Taski Ergodisc 165 de la Diversey, de viteza redusa, pentru sarcini multiple de curatenie, va asigura: ergonomie, siguranta, simplitate si versatilitate",
        quantity: "10 BUC",
        dayPrice: "200 ron",
        weekPrice: "1.260 ron",
        monthPrice: "4.800 ron"
    },
    {
        id: "21",
        category: "masini-de-slefuit-glet",
        name: "Flex GE5",
        image: "/product_images/masini-de-slefuit-glet/GE5.jpeg",
        availability: 1,
        make: "Flex",
        model: "GE5",
        location: "Galati, Romania",
        description: "Masina de slefuit tip girafa Flex GE5+TB-L+SH, 500 W, 225 mm",
        quantity: "1 BUC",
        dayPrice: "400 ron",
        weekPrice: "2.520 ron",
        monthPrice: "9.600 ron"
    },
    {
        id: "22",
        category: "masini-de-slefuit-glet",
        name: "Bosch PEX 300 AE",
        image: "/product_images/masini-de-slefuit-glet/300AE.webp",
        availability: 1,
        make: "Bosch",
        model: "PEX 300 AE",
        location: "Galati, Romania",
        description: "Bosch PEX 300 AE - Slefuitor cu excentric, 270 W, 125 mm, sistem antivibratii, turatie reglabila, valiza plastic",
        quantity: "7 BUC",
        dayPrice: "120 ron",
        weekPrice: "756 ron",
        monthPrice: "2.880 ron"
    },
    {
        id: "23",
        category: "echipamente-spalare-presiune",
        name: "Imer 150-10EM",
        image: "/product_images/echipamente-spalare-presiune/10EM.png",
        availability: 1,
        make: "Imer",
        model: "150-10EM",
        location: "Galati, Romania",
        description: "IMER HPSTAR 150-10 EM Motopompa de spalat cu presiune, 3.0 kW",
        quantity: "1 BUC",
        dayPrice: "400 ron",
        weekPrice: "2.520 ron",
        monthPrice: "9.600 ron"
    },
    {
        id: "24",
        category: "echipamente-spalare-presiune",
        name: "Karcher HD 10-25 4S",
        image: "/product_images/echipamente-spalare-presiune/4S.jpeg",
        availability: 1,
        make: "Karcher",
        model: "HD 10/25 4S",
        location: "Galati, Romania",
        description: "Curatitor cu inalta presiune, fara incalzire, in pozitie verticala. Servo Control pentru reglarea presiunii si a debitului apei direct de pe pistol, intrerupator de presiune, pistol Easy-Press cu maner cu prindere moale, lance de pulverizare pivotanta din otel inoxidabil, rezervor pentru detergent, compartiment separat pentru duze.",
        quantity: "1 BUC",
        dayPrice: "600 ron",
        weekPrice: "3.780 ron",
        monthPrice: "14.400 ron"
    },
    {
        id: "25",
        category: "pompe-var",
        name: "Wagner SF 23 PRO",
        image: "/product_images/pompe-var/23PRO.jpeg",
        availability: 1,
        make: "Wagner",
        model: "SF 23 PRO",
        location: "Galati, Romania",
        description: "Pompa cu tehnologie fara aer la presiune scazuta, cu pana la 55% mai putine particule de vopsea eliberate in aer",
        quantity: "1 BUC",
        dayPrice: "400 ron",
        weekPrice: "2.520 ron",
        monthPrice: "9.600 ron"
    },
    {
        id: "26",
        category: "tunuri-caldura-motorina",
        name: "Zobo ZB K215 putere 63kW",
        image: "/product_images/tunuri-de-caldura-motorina/63kW.jpeg",
        availability: 1,
        make: "Zobo",
        model: "ZB K215",
        location: "Galati, Romania",
        description: "Model robust, constructie din otel rezistent. Maner usor de montat, asigura manevrabilitate facila. Termostat reglabil al temperaturii cu oprire si pornire automata. Aprindere electrica. Alegerea economica în medii industriale, comerciale, construcții sau agricole",
        quantity: "3 BUC",
        dayPrice: "250 ron",
        weekPrice: "1.575 ron",
        monthPrice: "6.000 ron"
    },
    {
        id: "27",
        category: "tunuri-caldura-motorina",
        name: "Energo 85kW",
        image: "/product_images/tunuri-de-caldura-motorina/85kW.webp",
        availability: 1,
        make: "Energo",
        model: "85kW",
        location: "Galati, Romania",
        description: "INCALZITOR CU MOTORINA ARDERE INDIRECTA MIR85WE 85KW-73087KCAL/H DEBIT4500MC/H CONS-8.29L/H ENERGO",
        quantity: "1 BUC",
        dayPrice: "1000 ron",
        weekPrice: "6.300 ron",
        monthPrice: "24.000 ron"
    },
    {
        id: "28",
        category: "dezumidificatoare",
        name: "MASTER DH 92",
        image: "/product_images/dezumidificatoare/DH92.jpeg",
        availability: 1,
        make: "MASTER",
        model: "DH 92",
        location: "Galati, Romania",
        description: "Putere :1350 W Alimentare : 230 V/50 Hz Capacitate de umidificare :80 l/24 h Volum aer circulat :1000 m3 Capacitate rezervor apa :15.0 l Agent de racire :R 407C Dimensiuni :580x560x1020 mm Greutate :66.0 kg",
        quantity: "1 BUC",
        dayPrice: "500 ron",
        weekPrice: "3.150 ron",
        monthPrice: "12.000 ron"
    },
    {
        id: "29",
        category: "drujbe",
        name: "Drujba Stihl MS 170",
        image: "/product_images/drujbe/MS170.jpeg",
        availability: 1,
        make: "Stihl",
        model: "MS 170",
        location: "Galati, Romania",
        description: "Motoferastrau cu lant STIHL MS 170, 40 cm lungime, 1.1mm",
        quantity: "1 BUC",
        dayPrice: "200 ron",
        weekPrice: "1.260 ron",
        monthPrice: "4.800 ron"
    },
    {
        id: "30",
        category: "nivele-optice",
        name: "Nivela optica Bosch BT 160",
        image: "/product_images/nivele-optice/BT160.jpeg",
        availability: 1,
        make: "Bosch",
        model: "BT 160",
        location: "Galati, Romania",
        description: "Nivelă optică Bosch Professional GOL20G, incl. stativ de fixare BT160 + miră telescopică GR500",
        quantity: "1 BUC",
        dayPrice: "150 ron",
        weekPrice: "945 ron",
        monthPrice: "3.600 ron"
    },
    {
        id: "31",
        category: "nivele-laser",
        name: "Nivela laser rotative RL H5A Topcon",
        image: "/product_images/nivele-laser/TOPCON.webp",
        availability: 1,
        make: "Topcon",
        model: "RL H5A",
        location: "Galati, Romania",
        description: "Pachet Nivela Laser Rotativa RL-H5A cu trepied si stadie - Topcon",
        quantity: "1 BUC",
        dayPrice: "200 ron",
        weekPrice: "1.260 ron",
        monthPrice: "4.800 ron"
    },
    {
        id: "32",
        category: "pistoale-gaz-cuie",
        name: "Pistol cu gaz impuscat cuie Spit Pulsa 800",
        image: "/product_images/pistoale-gaz-impuscat-cuie/PULSA800.jpeg",
        availability: 1,
        make: "Spit",
        model: "P800",
        location: "Galati, Romania",
        description: "Pistol pulsa cu gaz de la Spit P800 este dedicat fixarii de profile pentru rigips, OSB si alte materiale pe beton sau structuri metalice subtiri de 3-4 mm grosime",
        quantity: "1 BUC",
        dayPrice: "200 ron",
        weekPrice: "1.260 ron",
        monthPrice: "4.800 ron"
    },
    {
        id: "33",
        category: "demolatoare-rotopercutoare-percutie",
        name: "Bosch GBH 5-40 DCE",
        image: "/product_images/demolatoare-si-rotopercutoare/DCE.webp",
        availability: 1,
        make: "Bosch",
        model: "GBH 5-40 DCE",
        location: "Galati, Romania",
        description: "Ciocan rotopercutor, 1150W, 8.8J, SDS-max, Bosch GBH 5-40 DCE",
        quantity: "1 BUC",
        dayPrice: "300 ron",
        weekPrice: "1.890 ron",
        monthPrice: "7.200 ron"
    },
    {
        id: "34",
        category: "demolatoare-rotopercutoare-percutie",
        name: "Bosch GSH 11 E",
        image: "/product_images/demolatoare-si-rotopercutoare/11E.webp",
        availability: 1,
        make: "Bosch",
        model: "GSH 11 E",
        location: "Galati, Romania",
        description: "Ciocan demolator 1500W, 16.8J, SDS max, Bosch GSH 11 E",
        quantity: "1 BUC",
        dayPrice: "300 ron",
        weekPrice: "1.890 ron",
        monthPrice: "7.200 ron"
    },
    {
        id: "35",
        category: "demolatoare-rotopercutoare-percutie",
        name: "Bosch GBH 2-26 DRE",
        image: "/product_images/demolatoare-si-rotopercutoare/23DRE.webp",
        availability: 1,
        make: "Bosch",
        model: "GBH 2-26 DRE",
        location: "Galati, Romania",
        description: "Ciocan rotopercutor, 800W, 2.7J, SDS Plus, Bosch GBH 2-26 DRE",
        quantity: "3 BUC",
        dayPrice: "150 ron",
        weekPrice: "945 ron",
        monthPrice: "3.600 ron"
    },
    {
        id: "36",
        category: "masini-taiat-placi-ceramice",
        name: "Masina cu apa Battipav 857",
        image: "/product_images/masini-de-taiat-placi-ceramice/BATTIPAV857.webp",
        availability: 1,
        make: "Battipav",
        model: "857",
        location: "Galati, Romania",
        description: "Masina de taiat gresie, faianta, placi 125cm, 1.4kW, VIP 2125 - Battipav-857",
        quantity: "1 BUC",
        dayPrice: "500 ron",
        weekPrice: "3.150 ron",
        monthPrice: "12.000 ron"
    },
    {
        id: "37",
        category: "masini-taiat-placi-ceramice",
        name: "Montolit 1255 mm",
        image: "/product_images/masini-de-taiat-placi-ceramice/MONOLIT1255.jpeg",
        availability: 1,
        make: "Montolit",
        model: "1255 mm",
        location: "Galati, Romania",
        description: "Montolit Masterpiuma 125BP Echipament de taiat gresie, faianta, L 1250 mm",
        quantity: "1 BUC",
        dayPrice: "250 ron",
        weekPrice: "1.575 ron",
        monthPrice: "6.000 ron"
    },
    {
        id: "38",
        category: "masini-taiat-placi-ceramice",
        name: "Montolit 750 mm",
        image: "/product_images/masini-de-taiat-placi-ceramice/MONOLIT750.webp",
        availability: 1,
        make: "Montolit",
        model: "750 mm",
        location: "Galati, Romania",
        description: "Masina de taiat gresie faianta Montolit MasterPiuma 75BP, Lungime taiere 750 mm",
        quantity: "1 BUC",
        dayPrice: "150 ron",
        weekPrice: "945 ron",
        monthPrice: "3.600 ron"
    },
    {
        id: "39",
        category: "masini-taiat-placi-ceramice",
        name: "Sina Montolit FL 3",
        image: "/product_images/masini-de-taiat-placi-ceramice/FL3.jpeg",
        availability: 1,
        make: "Montolit",
        model: "FL 3",
        location: "Galati, Romania",
        description: "Masina de taiat placi de ceramica extra lungi Flash Line EVO3 de la Montolit",
        quantity: "1 BUC",
        dayPrice: "300 ron",
        weekPrice: "1.890 ron",
        monthPrice: "7.200 ron"
    },
    {
        id: "40",
        category: "schele-metalice",
        name: "Schela metalica tip Buzau",
        image: "/product_images/schele-metalice/SCHELE.jpeg",
        availability: 1,
        make: "-",
        model: "-",
        location: "Galati, Romania",
        description: "Schelă mobilă - multifuncțională, cu o gamă largă de aplicații.",
        quantity: "2000 ml",
        dayPrice: "15 ron / mp",
        weekPrice: "94 ron / mp",
        monthPrice: "360 ron / mp"
    },
    {
        id: "41",
        category: "cofraje",
        name: "Cofraje Doka",
        image: "/product_images/cofraje/DOKA.jpeg",
        availability: 1,
        make: "Doka",
        model: "-",
        location: "Galati, Romania",
        description: "Placa pentru cofraj. Placa constructii multistrat Doka 2 metri, rezistenta crescuta pentru pereti drepti. Cumpara cum si castiga timpi de lucru pretiosi pentru proiectul tau de constructii.",
        quantity: "1000 mp",
        dayPrice: "15 ron / mp",
        weekPrice: "94 ron / mp",
        monthPrice: "360 ron / mp"
    },
    {
        id: "42",
        category: "cofraje",
        name: "Cofraje stalpi",
        image: "/product_images/cofraje/STALPI.jpeg",
        availability: 1,
        make: "-",
        model: "-",
        location: "Galati, Romania",
        description: "Cravate reglabile pentru stalpi 15-45 set",
        quantity: "30 BUC",
        dayPrice: "50 ron / buc",
        weekPrice: "315 ron / buc",
        monthPrice: "1.200 ron / buc"
    },
    {
        id: "43",
        category: "popi-metalici",
        name: "Popi metalici 3.8 metri",
        image: "/product_images/popi-metalici/POPI.jpeg",
        availability: 1,
        make: "-",
        model: "-",
        location: "Galati, Romania",
        description: "Popi metalici 3.8 metri",
        quantity: "500 BUC",
        dayPrice: "10 ron / buc",
        weekPrice: "63 ron / buc",
        monthPrice: "240 ron / buc"
    }
];