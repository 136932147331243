import './index.css';
import React from 'react';
import {Helmet} from "react-helmet-async";

const ContactPage = () => {
    return (
        <div className="text-center">

            <Helmet>
                <title>Contact</title>
                <meta name="description" content="Romania, Galati, str. Silfidelor nr. 9 - Mobil: +40 747 479 634 - Email: office@epoxfloor.ro"/>
                <link rel="canonical" href="/contact" />
            </Helmet>

            <div className="card shadow-lg p-5">
                <h4 className="text-green">Contact</h4>
                <div className="my-4">
                    <div>Romania, Galati, str. Silfidelor nr. 9</div>
                    <div className="my-3">Mobil: <a href="tel:+40747479634" className="text-decoration-none text-dark">+40 747 479 634</a></div>
                    <div className="my-3">Email: <a href="mailto:office@epoxfloor.ro" className="text-decoration-none text-dark">office@epoxfloor.ro</a></div>
                    <div className="mt-5 d-flex justify-content-center">
                        <a style={{ width: "200px" }} href="https://www.google.ro/maps/place/Strada+Silfidelor+9,+Gala%C8%9Bi/@45.4362771,28.0385026,15z/data=!4m16!1m9!3m8!1s0x40b6de5637efc495:0xfaddece364ec58d0!2sStrada+Silfidelor+9,+Gala%C8%9Bi!3b1!8m2!3d45.4362771!4d28.0472573!10e5!16s%2Fg%2F11c264vrr0!3m5!1s0x40b6de5637efc495:0xfaddece364ec58d0!8m2!3d45.4362771!4d28.0472573!16s%2Fg%2F11c264vrr0" className="btn btn-primary" target="_blank">Obtine Directii</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
