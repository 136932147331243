import React from 'react';
import {Route, Routes} from "react-router-dom";
import HomePage from "../../Pages/Home";
import ProductPage from "../../Pages/Product";
import CategoryPage from "../../Pages/Category";
import SearchPage from "../../Pages/Search";
import TermsPage from "../../Pages/Terms";
import ContactPage from "../../Pages/Contact";
import PolicyPage from "../../Pages/Policy";

const RoutesComponent = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<HomePage/>} />
                <Route path="/:productCategory/:productName" element={<ProductPage/>} />
                <Route path="categorie/:categoryName" element={<CategoryPage/>} />
                <Route path="results/:searchQuery" element={<SearchPage/>} />
                <Route path="conditii-de-inchiriere" element={<TermsPage/>} />
                <Route path="contact" element={<ContactPage/>} />
                <Route path="politica-cookie" element={<PolicyPage/>} />
            </Routes>
        </div>
    );
};

export default RoutesComponent;
