import './index.css'
import React, { useEffect, useState } from "react";
import { BiChevronUp } from "react-icons/bi";


const TopButton = () => {

    // The back-to-top button is hidden at the beginning
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div>
            {showButton && (
                <button onClick={scrollToTop} className="back-to-top">
                    <BiChevronUp/>
                </button>
            )}
        </div>
    );
};

export default TopButton;
