import "./index.css";
import React from 'react';
import { SlSocialFacebook, SlSocialInstagram, SlSocialTwitter } from "react-icons/sl";

const FooterComponent = () => {
    return (
        <div className="footer-wrapper mt-5">
            <div className="py-3 mx-5 text-white text-center">
                <div className="py-5">
                    <div className="row">
                        <h3 className="title-bold">SynTech Echipamente</h3>
                    </div>
                    <div className="row d-inline">
                        <div className="my-1 text-uppercase d-flex flex-wrap justify-content-center">
                            <a href="/" className="px-2 footer-link">Acasa</a>
                            <a href="/conditii-de-inchiriere" className="px-2 footer-link">Conditii inchiriere</a>
                            <a href="/contact" className="px-2 footer-link">Contact</a>
                            <a href="https://epoxfloor.ro/" className="px-2 footer-link" target="_blank">EpoxFloor</a>
                            <a href="https://anpc.ro/" target="_blank" className="px-2 footer-link">ANPC</a>
                            <a href="/politica-cookie" className="px-2 footer-link">POLITICA COOKIES</a>
                        </div>
                    </div>
                    <div className="row d-inline">
                        <div className="my-1 text-uppercase">
                            <a href="#" className="px-2 footer-link social" target="_blank"><SlSocialFacebook /></a>
                            <a href="#" className="px-2 footer-link social" target="_blank"><SlSocialInstagram/></a>
                            <a href="#" className="px-2 footer-link social" target="_blank"><SlSocialTwitter/></a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="small">
                            Copyright © {new Date().getFullYear()} SynTech Flooring | Made by <a href="https://snapdesign.ro" className="snap-link" target="_blank">SnapDesign</a>
                        </div>
                    </div>
                    <div className="mt-5">
                        <a href="https://anpc.ro/ce-este-sal/" target="_blank" className="mx-1">
                            <img height="45" width="200" className="mb-2" src={require('./../../Assets/ANPC/SAL.png')}/>
                        </a>
                        <a href="https://ec.europa.eu/consumers/odr" target="_blank" className="mx-1">
                            <img height="45" width="200" className="mb-2" src={require('./../../Assets/ANPC/SOL.png')}/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterComponent;
