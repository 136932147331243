import './index.css';
import React from 'react';
import {useParams} from "react-router-dom";
import productsData from "../../Data/productsData";
import {Helmet} from "react-helmet-async";

const DetailsComponent = () => {

    const {productName} = useParams()
    const thisProduct = productsData.find(prod => (prod.name).replace(/\s+/g, '-').toLowerCase() === productName)

    return (
        <div className="card shadow-lg p-4">

            <Helmet>
                <title>{thisProduct.name}</title>
                <meta name="description" content={(thisProduct.description).substring(0, 170)}/>
                <link rel="canonical" href={'/' + thisProduct.category + '/' + (thisProduct.name).replace(/\s+/g, '-').toLowerCase()} />
            </Helmet>

            <div className="pb-3">
                <h2 className="text-bold">{thisProduct.name}</h2>
                <div>
                    <div>{thisProduct.availability ? <span className="badge-available">DISPONIBIL</span> : <span className="badge-unavailable">INCHIRIAT</span>}</div>
                </div>
            </div>
            <div className="row mt-5 pb-3">
                <div className="col-md-6 order-md-1 order-sm-last order-last">
                    <div className="row">
                        <div className="col-4">
                            <div className="text-bold">BRAND</div>
                            <div className="mt-2">{thisProduct.make}</div>
                        </div>
                        <div className="col-4">
                            <div className="text-bold">MODEL</div>
                            <div className="mt-2">{thisProduct.model}</div>
                        </div>
                        <div className="col-4">
                            <div className="text-bold">LOCATIE</div>
                            <div className="mt-2">{thisProduct.location}</div>
                        </div>
                    </div>

                    <div className="row mt-5 pb-3">
                        <div className="text-bold">DESCRIERE</div>
                        <div className="mt-3">{thisProduct.description}</div>
                    </div>

                    <div className="row mt-5 pb-3">
                        <div><span className="text-bold">CANTITATE: </span> {thisProduct.quantity}</div>
                    </div>

                    <div className="row mt-5 pb-3">
                        <div className="text-bold">INFORMATII ADITIONALE</div>
                        <div className="mt-3">

                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col" className="text-bold">PERIOADA</th>
                                    <th scope="col" className="text-bold">PRET</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1 zi</td>
                                    <td>{thisProduct.dayPrice}</td>
                                </tr>
                                <tr>
                                    <td>1 saptamana</td>
                                    <td>{thisProduct.weekPrice}</td>
                                </tr>
                                <tr>
                                    <td>1 luna</td>
                                    <td>{thisProduct.monthPrice}</td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div className="col-md-6 order-md-2 mb-5 order-sm-first">
                    <img draggable="false" className="product-image-detail" src={thisProduct.image} alt="product-image" />
                </div>
            </div>
        </div>
    );
};

export default DetailsComponent;
