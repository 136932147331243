import './index.css';
import React from 'react';
import {Helmet} from "react-helmet-async";

const TermsPage = () => {
    return (
        <div>

            <Helmet>
                <title>Termene si conditii</title>
                <meta name="description" content="Termene si conditii inchirieri echipamente"/>
                <link rel="canonical" href="/conditii-de-inchiriere" />
                <meta name="robots" content="noindex"/>
            </Helmet>

            <div className="row card shadow-lg mx-1 p-5">
                <h4 className="text-green">Conditii generale de inchiriere</h4>
                <div className="my-3">
                    1. Expedierea echipamentului de la depozitul SynTech se face pe contul si
                    riscul BENEFICIAR-ului, ca si costurile pentru ambalare si modul de
                    expediere dorit.
                </div>
                <div className="my-3">
                    2. Prelungirea perioadei de inchiriere se poate face printr-un alt Contrat de
                    inchiriere.
                </div>
                <div className="my-3">
                    3. Garantia va fi folosita in momentul in care, la restituirea echipamentului,
                    se constata defectiuni aparute din vina beneficiarului. Beneficiarul va primi
                    inapoi diferenta dintre valoarea chiriei si costul reparatiei.
                </div>
                <div className="my-3">
                    4. Facturile se platesc imediat dupa primirea lor, la cursul BNR din ziua
                    emiterii facturii, sau conform termenului de plata stabilit intre SynTech si
                    beneficiar in contractul comercial cadru de vanzare-cumparare pentru
                    anul in curs.
                </div>
                <div className="my-3">
                    5. Pentru depasirea termenului de plata, beneficiarul va plati penalitati de
                    intarziere de 0,5% pe zi, valoarea penalitatilor putand depasi valoarea
                    facturii.
                </div>
                <div className="my-3">
                    6. Beneficiarul trebuie sa examineze echipamentul imediat, la primire,
                    inainte de preluare. Daca nu se face nici o obiectie, inseamna ca
                    echipamentul este complet si corespunde scopului pentru care a fost
                    inchiriat, in conformitate cu specificatiile producatorului. Suprasolicitarea
                    nu este permisa.
                </div>
                <div className="my-3">
                    7. Daca echipamentul este furat, pierdut sau deteriorat ireparabil,
                    beneficiarul trebuie sa plateasca catre SynTech valoarea echipamentului,
                    inscrisa in PVP-P.
                </div>
                <div className="my-3">
                    8. In cazul in care beneficiarul restituie echipamentele cu sigiliul distrus
                    acesta va achita la data predarii contravaloarea remedierii interventiei
                    sale neautorizate.
                </div>
                <div className="my-3">
                    9. Beneficiarul nu are voie sa instraineze cu titlu gratuit sau contra cost
                    nimanui fara consimtamantul exprimat in scris, al SynTech.
                </div>
                <div className="my-3">
                    10. In cazul in care beneficiarul nu returneaza echipamentele si accesoriile
                    in termen maxim de 7 zile de la expirarea contractului, beneficiarul
                    recunoaste insusirea ilegala a acestora si isi asuma urmarile juridice
                    aferente. In caz contrat, contractul reprezinta titlu executoriu pentru
                    incasarea garantiei de catre SynTech, diferenta ramasa neacoperita
                    urmand a se recupera pe cale judecatoreasca.
                </div>
                <div className="my-3">
                    11. Sunt necesare: respectarea indicatiilor de functionare, intretinerea si
                    ingrijirea conform instructiunilor producatorului, controlul zilnic al uleiului,
                    schimbarea uleiului la termenele stabilite, gresarea, intretinerea si
                    inlocuirea filtrelor de: combustibil, ulei sau aer.
                </div>
                <div className="my-3">
                    12. Defectiunile sau daunele aduse echipamentului trebuie anuntate
                    imediat. Reparatiile sau reviziile pot fi intreprinse numai de SynTech sau un
                    delagat al sau. In caz de furt, pierdere, pagube produse de fortarea pe
                    santier sau alte pagube (ex. incendiu) beneficiarul raspunde fata de
                    SynTech prin platirea daunelor ca si pentru costurile provocate de
                    imposibilitatea de a inchiria echipamentul. Beneficiarul este raspunzator
                    de pagubele determinate de folosirea necorespunzatoare. Remedierea
                    defectiunilor si inlaturarea daunelor suplimentare se fac pe cheltuiala
                    beneficiarului.
                </div>
                <div className="my-3">
                    13. Un delegat SynTech are dreptul sa viziteze oricand echipamentul
                    inchiriat, spre a se convinge de disponibilitatea si starea sa. Beneficiarul
                    trebuie sa restituie echipamentul intr-o stare ireprosabila, care sa nu
                    necesite cheltuieli de punere in functiune.
                </div>
                <div className="my-3">
                    14. SynTech pastreaza proprietatea asupra echipamentului si accesoriile
                    inchiriate.
                </div>
                <div className="my-3">
                    15. Orice modificare a acestui contract se face in scris. Nevalabilitatea unei
                    singure prevederi nu are influenta asupra valabilitatii restului de prevederi.
                </div>
                <div className="my-3">
                    16. Orice litigiu aparut intre parti va fi solutionat pe cale amiabila. In caz
                    contrat, litigiul va fi solutionat de instantele competente de pe raza
                    judetului Galati.
                </div>
            </div>
        </div>
    );
};

export default TermsPage;
