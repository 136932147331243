import './App.css';
import RoutesComponent from "./Config/Routes/web";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import HeaderComponent from "./Layouts/Header";
import NavbarComponent from "./Layouts/Navbar";
import SidebarComponent from "./Layouts/Sidebar";
import FooterComponent from "./Layouts/Footer";
import TopButton from "./Layouts/TopButton";
import CookieConsent from "react-cookie-consent";
import CallButton from "./Layouts/CallButton";

function App() {
    return (
    <div>
        <NavbarComponent />
        <HeaderComponent />
        <div className="container mt-5">
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-3 mt-5">
                    <SidebarComponent />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 mt-5">
                    <RoutesComponent />
                </div>
            </div>
        </div>
        <TopButton />
        <CallButton />
        <FooterComponent />
        <CookieConsent
            location="bottom"
            buttonText="Sunt de acord"
            cookieName="SyntechEquipments-Cookies-Consent"
            style={{ background: "#2B373B", zIndex: 999999 }}
            buttonStyle={{ color: "#ffffff", backgroundColor: "#1EA951", fontSize: "16px" }}
            expires={30}
        >
            Acest website foloseste cookieuri pentru o mai buna performanta.
            <a href="politica-cookie" className="text-decoration-none text-green"> Citeste mai multe aici</a>
        </CookieConsent>
    </div>
  );
}

export default App;
